import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { CardProvider } from "acciondigital-basic-theme/src/components/CartContext"
import AuthProvider from "acciondigital-basic-theme/src/context/AuthContext"

const queryClient = new QueryClient()

// export const RootElement = ({ children }) => (
//   <AuthProvider>
//     <QueryClientProvider client={queryClient}>
//       <CardProvider>
//         {children}
//         {/* <ReactQueryDevtools initialIsOpen={false} /> */}
//       </CardProvider>
//     </QueryClientProvider>
//   </AuthProvider>
// )

export const RootElement = ({ children }) => (
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <CardProvider>{children}</CardProvider>
    </QueryClientProvider>
  </AuthProvider>
)

export default RootElement
